export const UserPoolClientId = '5hjighnd84rnqb7oopgfcm0h4j';
export const AppSyncAPIKey = 'da2-twh426ndefaarlnr4ey3ce3gt4';
export const UserPoolId = 'eu-west-1_3hCNSTsxD';
export const GraphQLAPIURL = 'https://5wx7xt5l3vhohnrcngtvcdnbtm.appsync-api.eu-west-1.amazonaws.com/graphql';
export const IdentityPoolId = 'eu-west-1:7096c8a8-c5f5-4573-bd6e-413fe31be4ad';
export const target = 'STAG';
export const region = 'eu-west-1';

const config = {
  aws_project_region: region,
  aws_user_pools_id: UserPoolId,
  aws_user_pools_web_client_id: UserPoolClientId,
  aws_appsync_graphqlEndpoint: GraphQLAPIURL,
  aws_appsync_apiKey: AppSyncAPIKey,
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    identityPoolId: IdentityPoolId,
    region,
    userPoolId: UserPoolId,
    userPoolWebClientId: UserPoolClientId,
  },
};

export default config;
